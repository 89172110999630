<template>
  <q-card class="column justify-center">
    <div>
      <p class="text-center text-bold text-h6">LOGIN ADMIN</p>
      <q-card-section>
        <div class="column q-gutter-sm">
          <q-input
            outlined
            label="Username"
            @keyup.enter="passfield.focus()"
            v-model="data.username"
          ></q-input>
          <q-input
            ref="passfield"
            outlined
            label="Password"
            v-model="data.password"
            :type="data.is_password ? 'password' : ''"
            @keyup.enter="login"
          >
            <template v-slot:append>
              <q-icon
                :name="data.is_password ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="data.is_password = !data.is_password"
              ></q-icon>
            </template>
          </q-input>
        </div>
      </q-card-section>
      <q-card-actions align="stretch">
        <q-btn
          unelevated
          color="primary"
          class="full-width"
          label="LOGIN"
          @click="login"
        ></q-btn>
      </q-card-actions>
    </div>
  </q-card>
</template>

<script>
import useBasic from "@/composables/useBasic";
export default {
  setup() {
    const {
      reactive,
      ref,
      onMounted,
      route,
      router,
      loadThis,
      isLoadingData,
      $http,
      moment,
      waitThis,
      $q,
      sHeight,
    } = useBasic();

    let data = reactive({
      username: "",
      password: "",

      is_password: true,
    });

    onMounted(() => {});

    let login = async () => {
      let payload = {
        username: data.username.slice(),
        password: data.password.slice(),
      };
      const md5 = require("md5");
      payload.password = md5(payload.password);

      try {
        let resp = await $http.post("/login", payload);
        localStorage.setItem("token-toko-masjid", resp.data);

        router.push("/admin/menu");
        $q.notify({ message: "Login Berhasil", color: "positive" });
      } catch (err) {
        $q.notify({ message: "Login Gagal", color: "negative" });
      }
    };

    let passfield = ref(null);

    return {
      data,
      passfield,
      isLoadingData,
      login,
      router,
    };
  },
};
</script>
